import React from "react"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import { graphql } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const NotFound = ({ data, location }) => {
    let global = data.contentfulWebsite;
    return (
        <Layout pathname={location.pathname}>
            <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-content o-container">
                <div className="u-text-center u-text-large o-content-spacer o-content-spacer--large">
                    {documentToReactComponents(JSON.parse(global.pageNotFoundMessage.raw))}
                </div>
            </div>
        </Layout>
    );
};

export default NotFound

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "UNINTERRUPTED Canada"}) {
        author
        description
        title
        pageNotFoundMessage {
            raw
        }
        shareImage {
            file {
                url
            }
        }
    }
}
`;
